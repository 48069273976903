<template>
  <div class="wp-body">
    <div class="wp-header mb-4">
      <h1 class="obj-name">{{ $t('organizations') }}</h1>
    </div>
    <TestTree />
  </div>
</template>

<script>
import TestTree from "@/views/TreeData/test/DragComponent.vue";
export default {
  components: {
    TestTree,
  },
  data() {
    return {
      value: "tree",
    };
  },
};
</script>

<style lang="scss" scoped>
.wp-body {
  overflow-y: auto;
}
</style>
