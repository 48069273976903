<template>
  <div>
    <el-tree :data="data" :props="defaultProps" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <template slot-scope="{ node, data }">
        <span style="width: 100%;" @mouseover="showEditIcon(node)" @mouseleave="hideEditIcon(node)">
          <template v-if="data">
            {{ data.label }}
            <span class="text-silver" v-if="data.children_num">
              ({{ data.children_num }})
            </span>
          </template>
          <i
            v-if="node.isHovered"
            class="el-icon-edit"
            @click="openDialog(data)"
          ></i>
        </span>
      </template>
    </el-tree>
    <el-dialog :title="$t('change')" :visible.sync="dialogVisible">
      <div class="dialog__wrapper flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <span>{{  $t('org_name') }}</span>
          <el-input
            v-model="OrgName"
            :placeholder="$t('org_name')"
          >
          </el-input>
        </div>
        <div class="flex flex-col gap-2">
          <span>
            {{ $t('org_adress') }}
          </span>
          <treeselect
            :options="data"
            v-model="value"
            :placeholder="$t('org_adress')"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer"></span>
      <el-dialog
        width="40%"
        title="Огохлантириш"
        :visible.sync="innerVisible"
        append-to-body
        class="flex items-center justify-center"
      >
        <div class="flex flex-col gap-3">
          <div class="flex justify-between mb-5">
            <span>
              {{ $t('building_count')  }}:
              {{ buildingsInfo && buildingsInfo.generalinformation }} {{ $t('ta') }}
            </span>
            <span>
              {{ $t('organisation_count')  }}:
              {{ buildingsInfo && buildingsInfo.specification }} {{ $t('ta') }}
            </span>
          </div>

          <span
            >{{ selectedOrg && selectedOrg.label }} {{ $t('org_change_sure') }}</span
          >
          <div class="flex w-full justify-end">
            <el-button @click="innerVisible = false">{{ $t('cancel') }}</el-button>
            <el-button @click="applyChanges">{{ $t('save') }}</el-button>
          </div>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="innerVisible = true"
          >{{$t('approve_btn')}}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import axios from "axios";
export default {
  components: { Treeselect },
  data() {
    return {
      org_id: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      OrgName: "",
      dialogVisible: false,
      selectedOrg: null,
      value: null,
      outerVisible: false,
      innerVisible: false,
      buildingsInfo: null,
      oldName: null,
      loading: false
    };
  },
  methods: {
    _Get() {
      this.loading = true
      this.data = [];
      axios.get(`/organization/get_active/?id=${this.org_id}`).then((res) => {
        this.data.push(this.transformLabels(res.data));
      }).finally(() => {
        this.loading = false
      });
    },
    transformLabels(obj) {
      if (Array.isArray(obj)) {
        return obj.map((item) => this.transformLabels(item));
      } else if (typeof obj == "object" && obj !== null) {
        const newObj = {};
        for (const key in obj) {
          if (key == "name_cyr") {
            newObj["label"] = obj[key].toString();
          } else if (key == "children") {
            newObj["children"] = this.transformLabels(obj[key]);
          } else {
            newObj[key] = obj[key];
          }
        }
        return newObj;
      } else {
        return obj;
      }
    },
    showEditIcon(node) {
      this.$set(node, "isHovered", true);
    },
    hideEditIcon(node) {
      this.$set(node, "isHovered", false);
    },

    openDialog(selectedOrganization) {
      this.selectedOrg = selectedOrganization;
      this.OrgName = selectedOrganization.label;
      this.oldName = this.OrgName;
      this.dialogVisible = true;
      axios
        .get(`/organization/get_generalinfo/?id=${this.selectedOrg.id}`)
        .then((res) => {
          this.buildingsInfo = res.data;
        });
    },

    applyChanges() {
      let nameChanged = this.selectedOrg.name_cyr !== this.OrgName;
      let positionChanged = this.value !== null;
      if (nameChanged && positionChanged) {
        this.changeOrg();
      } else if (nameChanged) {
        this.changeOrgName();
      } else if (positionChanged) {
        this.changeOrgPosition();
      }
      this.сloseDialog();
    },

    сloseDialog() {
      this.dialogVisible = false;
      this.innerVisible = false;
    },

    changeOrgName() {
      this.selectedOrg.name_cyr = this.OrgName;
      const updatedName = {
        id: this.selectedOrg.id,
        name_cyr: this.selectedOrg.name_cyr,
      };
      axios.patch(`/organization/`, updatedName).then(() => {
        this.selectedOrg.label = this.selectedOrg.name_cyr;
      });
    },

    changeOrg() {
      this.selectedOrg.name_cyr = this.OrgName;
      const updatedOrg = {
        id: this.selectedOrg.id,
        name_cyr: this.selectedOrg.name_cyr,
        parent: this.value,
      };
      axios.patch(`/organization/`, updatedOrg).then(() => {
        this._Get();
      }).finally(() => {
        this.value = null
      });
    },

    changeOrgPosition() {
      const orgPosition = {
        id: this.selectedOrg.id,
        parent: this.value,
      };
      axios.patch(`/organization/`, orgPosition).then(() => {
        this._Get();
      });
    },
  },

  mounted() {
    this.org_id = this.$route.query.id;
    this._Get();
  },
};
</script>

<style lang="scss">
.text-silver {
  color: rgb(160, 160, 160);
  font-weight: 400;
}

.vue-treeselect {
  box-shadow: none !important;
  &__control {
    background: linear-gradient(135deg, #f2f2f2 0%, #ffffff 100%) !important;
    border: 1px solid #004787 !important;
    color: #004787 !important;
    font-weight: 600 !important;
    height: 40px;
    vertical-align: middle;
    .vue-treeselect__value-container {
      .vue-treeselect__single-value {
        color: #004787 !important;
        font-weight: 600 !important;
      }
      .vue-treeselect__input-container {
        .vue-treeselect__input {
          font-weight: 600 !important;
        }
      }
    }
  }
}
</style>
